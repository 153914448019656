
import { useQuery } from 'react-query';
import {
  withErrorBoundary,
  useErrorHandler
} from 'react-error-boundary';
import clsx from 'clsx';

import ErrorFallback from 'components/ErrorFallback';
import {
  getCrowdloan,
  Crowdloan,
  CROWDLOAN_DATA_QUERY_KEY
} from 'services/sub-query';

const KINT_TO_BE_DISTRIBUTED = 1000000;

const CrowdloanStats = (): JSX.Element => {
  const {
    isIdle: crowdloanDataIdle,
    isLoading: crowdloanDataLoading,
    data: crowdloanData,
    error: crowdloanDataError
  } = useQuery<Crowdloan, Error>([CROWDLOAN_DATA_QUERY_KEY], getCrowdloan);
  useErrorHandler(crowdloanDataError);

  if (crowdloanDataIdle || crowdloanDataLoading) {
    // TODO: should use skeleton loaders
    return <>Loading...</>;
  }
  if (crowdloanData === undefined) {
    throw new Error('Something went wrong!');
  }

  const participants = crowdloanData.totalCount.toLocaleString();
  const ksmContributed = crowdloanData.crowdloan.raised.to.KSM().round().toNumber().toLocaleString();

  return (
    <div
      className={clsx(
        'grid',
        'grid-cols-1',
        'md:grid-cols-3',
        'gap-5'
      )}>
      <div
        className={clsx(
          'p-5',
          'shadow',
          'md:rounded-lg'
        )}>
        <h4
          className={clsx(
            'text-center',
            'text-lg',
            'font-bold',
            'text-kintsugiOchre'
          )}>
          Participants
        </h4>
        <span
          className={clsx(
            'text-5xl',
            'text-center',
            'block'
          )}>
          {participants}
        </span>
      </div>
      <div
        className={clsx(
          'p-5',
          'shadow',
          'md:rounded-lg'
        )}>
        <h4
          className={clsx(
            'text-center',
            'text-lg',
            'font-bold',
            'text-kintsugiOchre'
          )}>
          KSM Contributed
        </h4>
        <span
          className={clsx(
            'text-5xl',
            'text-center',
            'block'
          )}>
          {ksmContributed}
        </span>
      </div>
      <div
        className={clsx(
          'p-5',
          'shadow',
          'md:rounded-lg'
        )}>
        <h4
          className={clsx(
            'text-center',
            'text-lg',
            'font-bold',
            'text-kintsugiOchre'
          )}>
          KINT to be distributed
        </h4>
        <span
          className={clsx(
            'text-5xl',
            'text-center',
            'block'
          )}>
          {KINT_TO_BE_DISTRIBUTED.toLocaleString()}
        </span>
      </div>
    </div>
  );
};

export default withErrorBoundary(CrowdloanStats, {
  FallbackComponent: ErrorFallback,
  onReset: () => {
    window.location.reload();
  }
});
