import axios, { AxiosResponse } from 'axios';

import config from 'config';

interface ClaimKintQuery {
  accountId: string;
  signature: string;
}

interface ClaimKintResponse {
  data: {
    ok: boolean;
  }
}

async function getLegalText(): Promise<AxiosResponse> {
  return axios.get<string>(`${config.SIGNER_API_URL}/legaltext`);
}

async function claimKint(variables: ClaimKintQuery): Promise<AxiosResponse> {
  return await axios.post<ClaimKintResponse>(
    `${config.SIGNER_API_URL}/accept`,
    {
      accountid: variables.accountId,
      signature: variables.signature
    }
  );
}

const LEGAL_TEXT_QUERY_KEY = 'legal-text-query-key';
const CLAIM_KINT_QUERY_KEY = 'claim-kint-query-key';

export type {
  ClaimKintQuery,
  ClaimKintResponse
};

export {
  getLegalText,
  claimKint,
  LEGAL_TEXT_QUERY_KEY,
  CLAIM_KINT_QUERY_KEY
};
