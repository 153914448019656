import clsx from 'clsx';

import CrowdloanStats from 'containers/CrowdloanStats';

const KintsugiCrowdloanTodaySection = (): JSX.Element => (
  <section className='space-y-10'>
    <>
      <h1
        className={clsx(
          'text-center',
          'text-4xl',
          'font-abcWhyte'
        )}>
        The Kintsugi Crowdloan has ended &#8212; 200,000 KSM max cap reached
      </h1>
      <p
        className={clsx(
          'text-center',
          'text-xl',
          'font-bold',
          'text-kintsugiOchre'
        )}>
        Thanks for Voting for trustless Bitcoin on Kusama
      </p>
    </>
    <CrowdloanStats />
  </section>
);

export default KintsugiCrowdloanTodaySection;
