import * as React from 'react';
import { useQuery, useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { KeyringPair } from '@polkadot/keyring/types';
import { encodeAddress } from '@polkadot/util-crypto';
import clsx from 'clsx';
import { AxiosResponse, AxiosError } from 'axios';

import {
  getLegalText,
  claimKint,
  ClaimKintQuery,
  LEGAL_TEXT_QUERY_KEY
} from 'services/signer';
import { signMessage } from 'utils/helpers/sign-message';
import { TERMS_AND_CONDITIONS_LINK } from 'config/links';
import InterlayLink from 'components/UI/InterlayLink';
import ErrorMessage from 'components/ErrorMessage';
import KintsugiStilettoContainedButton from 'components/buttons/KintsugiStilettoContainedButton';

const TERMS = 'terms';
const loadingMessage = 'Loading...';
const signingMessage = 'Please sign the terms and conditions';
const termsValidationError = 'Please accept the terms and conditions.';
const legalTextErrorMessage = 'There was a problem loading the legal text.';
const signatureErrorMessage = 'There was a problem with your signature.';
// eslint-disable-next-line max-len
const duplicateClaimErrorMessage = 'You have already claimed your KINT. It may take up to 48 hours for the airdrop to be scheduled.';
// eslint-disable-next-line max-len
const apiSuccessMessage = 'You have successfully claimed your KINT. It may take up to 48 hours for the airdrop to be scheduled.';
const apiErrorMessage = 'Sorry, there was a problem processing your claim.';

type ClaimKintFormData = {
  [TERMS]: boolean;
}

interface Props {
  accountPair: KeyringPair;
}

const ClaimKintForm = ({ accountPair }: Props): JSX.Element => {
  const [legalText, setLegalText] = React.useState<string | undefined>(undefined);
  const [userMessage, setUserMessage] = React.useState<string | undefined>(undefined);

  const {
    data: legalTextData,
    error: legalTextError,
    isLoading: legalTextLoading
  } = useQuery<AxiosResponse, Error>([LEGAL_TEXT_QUERY_KEY], getLegalText);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm<ClaimKintFormData>({
    mode: 'onChange'
  });

  const claimKintMutation = useMutation<void, AxiosError, ClaimKintQuery>(
    async (variables: ClaimKintQuery) => {
      await claimKint(variables);
    },
    {
      onSuccess: () => {
        setUserMessage(apiSuccessMessage);
      },
      onError: (error: AxiosError) => {
        // Server returns a 409 if a user has already made a claim
        const userHasClaimed = error.response?.status === 409;
        setUserMessage(userHasClaimed ? duplicateClaimErrorMessage : apiErrorMessage);
      }
    }
  );

  React.useEffect(() => {
    if (legalTextLoading) {
      setLegalText(loadingMessage);
    }
  }, [legalTextLoading]);

  React.useEffect(() => {
    if (legalTextData) {
      setLegalText(legalTextData.data);
    }
  }, [legalTextData]);

  React.useEffect(() => {
    if (legalTextError) {
      setUserMessage(legalTextErrorMessage);
    }
  }, [legalTextError]);

  const onSigningCompleted = (signature: string | undefined) => {
    if (!signature) {
      setUserMessage(signatureErrorMessage);

      return;
    }

    // Set here in preference to mutation loading variable
    // so the message is displayed immediately.
    setUserMessage(loadingMessage);
    claimKintMutation.mutate({ accountId: encodeAddress(accountPair.address, 2), signature });
  };

  const onSubmitClaimKint = async () => {
    const termsAccepted = getValues(TERMS);

    if (!termsAccepted || !legalText) return;

    setUserMessage(signingMessage);

    const signature = await signMessage(accountPair, legalText);

    // TODO: client-side signature validation. Note: this is not a
    // blocker for launch as signature is being correctly validated
    // on the server.
    onSigningCompleted(signature);
  };

  return (
    <>
      {userMessage ?
        <p
          className={clsx(
            'text-center',
            'block',
            'text-xl'
          )}>{userMessage}
        </p> :
        <form onSubmit={handleSubmit(onSubmitClaimKint)}>
          <div
            className={clsx(
              'flex',
              'items-center',
              'justify-center',
              'space-x-2'
            )}>
            <input
              type='checkbox'
              id='terms'
              {...register(TERMS, {
                required: {
                  value: true,
                  message: termsValidationError
                }
              })} />
            <label htmlFor='terms'>
              I accept the&nbsp;
              <InterlayLink
                className='underline'
                href={TERMS_AND_CONDITIONS_LINK}
                target='_blank'
                rel='noopener noreferrer'>
                Terms &amp; Conditions.
              </InterlayLink>
            </label>
          </div>
          {errors[TERMS]?.message && (
            <ErrorMessage
              className={clsx(
                'mt-1',
                'flex',
                'justify-center'
              )}>{errors[TERMS]?.message}
            </ErrorMessage>
          )}
          <div
            className={clsx(
              'mt-4',
              'flex',
              'justify-center'
            )}>
            <KintsugiStilettoContainedButton
              type='submit'
              className={clsx(
                'h-14',
                'text-xl'
              )}>
              Claim KINT
            </KintsugiStilettoContainedButton>
          </div>
        </form>
      }
    </>
  );
};

export default ClaimKintForm;
