
import clsx from 'clsx';

import InterlayLink from 'components/UI/InterlayLink';
import { KUSAMA_AUCTIONS_LINK } from 'config/links';

const TheKintsugiCrowdloanSection = (): JSX.Element => {
  return (
    <section className='space-y-10'>
      <h1
        className={clsx(
          'text-4xl',
          'text-center',
          'font-abcWhyte'
        )}>
        The Kintsugi Crowdloan
      </h1>
      <div
        className={clsx(
          'bg-kintsugiMidnight',
          'shadow',
          'md:rounded-lg',
          'p-5',
          'text-lg',
          'text-justify'
        )}>
        <p>
          {/* eslint-disable-next-line max-len */}
          Kintsugi participates in the Kusama parachain auctions and supports a crowdloan to enable the community to vote.
        </p>
        <p>
          To participate in the crowdloan, you need to lock your KSM and vote for Kintsugi to become a parachain.
        </p>
        <p
          className={clsx(
            'mt-8',
            'flex',
            'justify-center'
          )}>
          <InterlayLink
            className={clsx(
              'italic',
              'text-center',
              'underline',
              'text-kintsugiStiletto',
              'font-bold'
            )}
            href={KUSAMA_AUCTIONS_LINK}>
            About Kusama auctions
          </InterlayLink>
        </p>
      </div>
      <div
        className={clsx(
          'bg-kintsugiMidnight',
          'shadow',
          'md:rounded-lg',
          'p-5',
          'text-lg',
          'text-justify',
          'grid',
          'grid-cols-1',
          'md:grid-cols-2',
          'md:items-center',
          'gap-10'
        )}>
        <ul
          className={clsx(
            'list-disc',
            'list-inside',
            'text-kintsugiOchre',
            'font-bold'
          )}>
          <li>Target slot: 6–10</li>
          <li>Lease duration: 8 periods (maximum lease duration ~48 weeks)</li>
          <li>Maximum lockup target: {(200000).toLocaleString()} KSM</li>
        </ul>
        <ul
          className={clsx(
            'list-disc',
            'list-inside',
            'text-kintsugiOchre',
            'font-bold'
          )}>
          <li>Crowdloan Airdrop: 12%</li>
          <li>Crowdloan: 10% of the initial 4-year KINT supply (1 million KINT)</li>
          <li>Extra 2% in-protocol airdrops for crowdloan participants who run Vaults and mint kBTC.</li>
        </ul>
      </div>
      <div
        className={clsx(
          'bg-kintsugiMidnight',
          'shadow',
          'md:rounded-lg',
          'p-5',
          'text-lg',
          'text-justify',
          'space-y-10'
        )}>
        <h3
          className={clsx(
            'text-3xl',
            'text-center',
            'font-abcWhyte'
          )}>
          Reward Schedule
        </h3>
        <div
          className={clsx(
            'space-y-10'
          )}>
          <div>
            <p
              className={clsx(
                'text-center',
                'text-kintsugiOchre',
                'font-bold'
              )}>
              Kintsugi features the following special airdrops:
            </p>
            <ul
              className={clsx(
                'list-disc',
                'list-inside'
              )}>
              <li>
                <span
                  className={clsx(
                    'text-kintsugiOchre',
                    'font-bold'
                  )}>
                  Early Bird
                </span>
                <span>
                  : 5% for first 3 days of crowdloan
                </span>
              </li>
              <li>
                <span
                  className={clsx(
                    'text-kintsugiOchre',
                    'font-bold'
                  )}>
                  Referral
                </span>
                <span>
                  : 5% for both the referrer and referred
                </span>
              </li>
              <li>
                <span
                  className={clsx(
                    'text-kintsugiOchre',
                    'font-bold'
                  )}>
                  Strong Supporter
                </span>
                <span>
                  {/* eslint-disable-next-line max-len */}
                  : 0.5% bonus for contributions starting at 10 KSM, up to 5% bonus for contribution sof {(1000).toLocaleString()} KSM and above
                </span>
              </li>
              <li>
                <span
                  className={clsx(
                    'text-kintsugiOchre',
                    'font-bold'
                  )}>
                  Success
                </span>
                <span>
                  : up to {(100000).toLocaleString()} additional KINT if specific KSM lockup targets are achieved
                </span>
              </li>
            </ul>
          </div>
          <table
            className={clsx(
              'bg-kintsugiMidnight',
              'table-fixed',
              'w-full',
              'border-collapse',
              'border',
              'border-white'
            )}>
            <thead>
              <tr>
                <th
                  className={clsx(
                    'border',
                    'border-white',
                    'px-4',
                    'py-3'
                  )}>
                  Portion of max. KSM lockup
                </th>
                <th
                  className={clsx(
                    'border',
                    'border-white',
                    'px-4',
                    'py-3'
                  )}>
                  KSM locked range
                </th>
                <th
                  className={clsx(
                    'border',
                    'border-white',
                    'px-4',
                    'py-3'
                  )}>
                  Additionally airdropped KINT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  className={clsx(
                    'border',
                    'border-white',
                    'px-4',
                    'py-3'
                  )}>
                  15%
                </td>
                <td
                  className={clsx(
                    'border',
                    'border-white',
                    'px-4',
                    'py-3'
                  )}>
                  {/* TODO: hardcoded */}
                  {(30000).toLocaleString()}–{(60000).toLocaleString()} KSM
                </td>
                <td
                  className={clsx(
                    'border',
                    'border-white',
                    'px-4',
                    'py-3'
                  )}>
                  {(25000).toLocaleString()} KINT
                </td>
              </tr>
              <tr>
                <td
                  className={clsx(
                    'border',
                    'border-white',
                    'px-4',
                    'py-3'
                  )}>
                  30%
                </td>
                <td
                  className={clsx(
                    'border',
                    'border-white',
                    'px-4',
                    'py-3'
                  )}>
                  {(60000).toLocaleString()}–{(100000).toLocaleString()} KSM
                </td>
                <td
                  className={clsx(
                    'border',
                    'border-white',
                    'px-4',
                    'py-3'
                  )}>
                  {(50000).toLocaleString()} KINT
                </td>
              </tr>
              <tr>
                <td
                  className={clsx(
                    'border',
                    'border-white',
                    'px-4',
                    'py-3'
                  )}>
                  50%
                </td>
                <td
                  className={clsx(
                    'border',
                    'border-white',
                    'px-4',
                    'py-3'
                  )}>
                  {(100000).toLocaleString()}–{(200000).toLocaleString()} KSM
                </td>
                <td
                  className={clsx(
                    'border',
                    'border-white',
                    'px-4',
                    'py-3'
                  )}>
                  {(100000).toLocaleString()} KINT
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            {/* eslint-disable-next-line max-len */}
            Any KINT not used for the Core, Early and Strong Supporter airdrops will be distributed proportionally among all participants, further increasing the ratio of KINT per locked KSM.
          </p>
        </div>
      </div>
    </section>
  );
};

export default TheKintsugiCrowdloanSection;
