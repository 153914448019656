import * as React from 'react';
import clsx from 'clsx';
import { KeyringPair } from '@polkadot/keyring/types';
import { useSubstrate } from 'substrate-lib';
import {
  KeyringState,
  ApiState
} from 'substrate-lib/substrate-context';
import DeveloperConsole from 'substrate-lib/components/DeveloperConsole';

import Layout from 'parts/Layout';
import HeroSection from 'parts/HeroSection';
import KintsugiCrowdloanTodaySection from 'parts/KintsugiCrowdloanTodaySection';
import InterlayCrowdloanInformation from 'parts/InterlayCrowdloanInformation';
import SupportTheKintsugiCrowdloanSection from 'parts/SupportTheKintsugiCrowdloanSection';
import TheKintsugiCrowdloanSection from 'parts/TheKintsugiCrowdloanSection';
import TokenomicsAndTheTokenModelSection from 'parts/TokenomicsAndTheTokenModelSection';
import BackedBySection from 'parts/BackedBySection';
import HowItWorksSection from 'parts/HowItWorksSection';
import AuditedAndPeerReviewedSection from 'parts/AuditedAndPeerReviewedSection';
import ReferralsLeaderboard from 'containers/ReferralsLeaderboard';
import FullLoadingSpinner from 'components/FullLoadingSpinner';
import initAxios from 'utils/helpers/init-axios';

const App = (): JSX.Element => {
  // TODO: could create a `React.Context`
  const [selectedAccountAddress, setSelectedAccountAddress] = React.useState<string>();
  const {
    state: {
      apiState,
      keyring,
      keyringState,
      apiError
    }
  } = useSubstrate();

  if (apiState === ApiState.Error) {
    return (
      // TODO: componentize
      <div>
        <p>Error Connecting to Substrate</p>
        <p>{JSON.stringify(apiError, null, 4)}</p>
      </div>
    );
  } else if (apiState !== ApiState.Ready) {
    return (
      <FullLoadingSpinner text='Connecting to Kusama' />
    );
  }
  if (keyringState !== KeyringState.Ready) {
    return (
      <FullLoadingSpinner text='Loading accounts (please review any extensions authorization)' />
    );
  }
  if (!keyring) {
    throw new Error('Something went wrong!');
  }

  let accountPair: KeyringPair | undefined;
  if (selectedAccountAddress) {
    accountPair = keyring.getPair(selectedAccountAddress);
  } else {
    accountPair = undefined;
  }

  initAxios();

  return (
    <>
      <Layout>
        <div
          className={clsx(
            'py-6',
            'md:py-28',
            'space-y-20',
            'max-w-7xl',
            'mx-auto'
          )}>
          <HeroSection />
          <KintsugiCrowdloanTodaySection />
          <InterlayCrowdloanInformation />
          <SupportTheKintsugiCrowdloanSection
            accountPair={accountPair}
            selectedAccountAddress={selectedAccountAddress}
            setSelectedAccountAddress={setSelectedAccountAddress} />
          <ReferralsLeaderboard />
          <HowItWorksSection />
          <TheKintsugiCrowdloanSection />
          <TokenomicsAndTheTokenModelSection />
          <BackedBySection />
          <AuditedAndPeerReviewedSection />
        </div>
      </Layout>
      <DeveloperConsole />
    </>
  );
};

export default App;
