import clsx from 'clsx';
import { KINTSUGI_TOKEN_ECONOMY_LINK } from 'config/links';

const TokenomicsAndTheTokenModelSection = (): JSX.Element => {
  return (
    <section className='space-y-10'>
      <h2
        className={clsx(
          'text-4xl',
          'text-center',
          'font-abcWhyte'
        )}>
        Tokenomics and the Token Model
      </h2>
      <div
        className={clsx(
          'bg-kintsugiMidnight',
          'shadow',
          'md:rounded-lg',
          'p-5',
          'text-justify'
        )}>
        <p
          className={clsx(
            'text-lg'
          )}>
          {/* eslint-disable-next-line max-len */}
          Kintsugi will be governed by its community from day 1 - via KINT, Kintsugi’s governance token. Kintsugi will go live via a fair launch scheme. Below is a summary of the Kintsugi token economy, as described in the <a href={KINTSUGI_TOKEN_ECONOMY_LINK}>token economy whitepaper (EN)</a> released by Kintsugi Labs.
        </p>
        <p
          className={clsx(
            'text-md',
            'text-kintsugiOchre',
            'italic'
          )}>
          {/* eslint-disable-next-line max-len */}
          KINT tokens will be distributed to network participants, builders and early backers as airdrops and block-rewards. There will be no public sale or ICO.
        </p>
      </div>
      <div
        className={clsx(
          'bg-kintsugiMidnight',
          'shadow',
          'md:rounded-lg',
          'p-5',
          'text-lg',
          'text-justify',
          'space-y-10'
        )}>
        <h3
          className={clsx(
            'text-3xl',
            'text-center',
            'font-abcWhyte'
          )}>
          Tokenomics
        </h3>
        <div>
          <p
            className={clsx(
              'text-center',
              'text-kintsugiOchre',
              'font-bold'
            )}>
            The KINT token&apos;s main purposes are:
          </p>
          <ul
            className={clsx(
              'list-disc',
              'list-inside'
            )}>
            <li>
              <span
                className={clsx(
                  'text-kintsugiOchre',
                  'font-bold'
                )}>
                Governance
              </span>
              <span>
                : KINT holders vote on governance proposals and elect council members to make operational decisions.
              </span>
            </li>
            <li>
              <span
                className={clsx(
                  'text-kintsugiOchre',
                  'font-bold'
                )}>
                Utility
              </span>
              <span>
                {/* eslint-disable-next-line max-len */}
                : Mid-term, Kintsugi will support transaction fee payments in KINT and other digital assets, in addition to KSM.
              </span>
            </li>
          </ul>
        </div>
        <div
          className={clsx(
            'grid',
            'grid-cols-1',
            'md:grid-cols-2',
            'gap-10'
          )}>
          <div className='space-y-3'>
            <h4
              className={clsx(
                'text-kintsugiOchre',
                'font-bold'
              )}>
              Supply and Emission
            </h4>
            <p>
              KINT features an unlimited supply. The emission schedule is defined as follows:
            </p>
            <ul
              className={clsx(
                'list-disc',
                'list-inside'
              )}>
              <li>10 million KINT emitted over the first 4 years (4:3:2:1 million)</li>
              <li>2% annual inflation afterwards</li>
            </ul>
          </div>
          <div className='space-y-3'>
            <h4
              className={clsx(
                'text-kintsugiOchre',
                'font-bold'
              )}>
              Distribution
            </h4>
            <p>
              KINT will be controlled by the community:
            </p>
            <ul
              className={clsx(
                'list-disc',
                'list-inside'
              )}>
              <li>
                <span className='font-bold'>70% is distributed to the community</span> as airdrops and block rewards.
              </li>
              <li>
                {/* eslint-disable-next-line max-len */}
                30% is airdropped to the Interlay team, and early & future investors, who funded development of the protocol - subject to 1 year followed by 1 year linear vesting.
              </li>
            </ul>
            <p>
              Starting in year 5, only the community receives new KINT from the annual inflation.
            </p>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          'bg-kintsugiMidnight',
          'shadow',
          'md:rounded-lg',
          'p-5',
          'text-lg',
          'text-justify',
          'space-y-10'
        )}>
        <h3
          className={clsx(
            'text-3xl',
            'text-center',
            'font-abcWhyte'
          )}>
          Governance
        </h3>
        <p>
          {/* eslint-disable-next-line max-len */}
          Kintsugi adopts <a href='https://wiki.polkadot.network/docs/learn-governance'>Polkadot&apos;s governance mechanism</a>, with two modifications: optimistic governance and stake-to-vote.
        </p>
        <div
          className={clsx(
            'grid',
            'grid-cols-1',
            'md:grid-cols-2',
            'gap-10'
          )}>
          <div>
            <h4
              className={clsx(
                'text-kintsugiOchre',
                'font-bold'
              )}>
              Council
            </h4>
            <p>
              {/* eslint-disable-next-line max-len */}
              Day-to-day decisions are made by an elected Council but can be overruled by the majority of KINT holders. The Council is democratically elected by KINT holders and anyone can run for office. <span className='text-bold'>A Council rules for 7 days and users can change their vote after each such period.</span>
            </p>
          </div>
          <div>
            <h4
              className={clsx(
                'text-kintsugiOchre',
                'font-bold'
              )}>
              Optimistic Governance
            </h4>
            <p>
              {/* eslint-disable-next-line max-len */}
              To promote a more active governance process and avoid the “lazy voter” problem, Kintsugi implements “optimistic governance”: proposals agreed on by Council pass automatically, after an “objection period”, unless KINT holders actively vote to oppose.
            </p>
          </div>
          <div>
            <h4
              className={clsx(
                'text-kintsugiOchre',
                'font-bold'
              )}>
              Stake to Vote
            </h4>
            <p>
              {/* eslint-disable-next-line max-len */}
              To vote, users lock KINT with the Kintsugi parachain. The locking period thereby will impact the voting power: the longer KINT are locked, the more voting power they have, since the voter has a long-term stake in the health of the system.
            </p>
          </div>
          <div>
            <h4
              className={clsx(
                'text-kintsugiOchre',
                'font-bold'
              )}>
              Technical Committee
            </h4>
            <p>
              {/* eslint-disable-next-line max-len */}
              Kintsuigi also exhibits a Technical Committee (TC) of developer teams, voted on by the Council. The TC can make emergency proposals which, if accepted by the council, are “fast-tracked” and executed quickly, e.g. in case of critical software bugs.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TokenomicsAndTheTokenModelSection;
