
import { Disclosure } from '@headlessui/react';
import {
  MenuIcon,
  XIcon
} from '@heroicons/react/outline';
import clsx from 'clsx';

import InterlayLink from 'components/UI/InterlayLink';
import {
  INTERLAY_COMPANY_LINK,
  KINTSUGI_TOKEN_ECONOMY_LINK,
  SHORT_PAPER_EN_LINK,
  SHORT_PAPER_ZH_LINK,
  INTERLAY_KINTSUGI_DOCS_OVERVIEW_LINK,
  BRIDGE_APP_LINK
} from 'config/links';
import { ReactComponent as KintsugiLogoIcon } from 'assets/img/kintsugi-logo.svg';

const RESOURCES = [
  {
    title: 'interBTC',
    link: INTERLAY_COMPANY_LINK
  },
  {
    title: 'Whitepaper (EN)',
    link: KINTSUGI_TOKEN_ECONOMY_LINK
  },
  {
    title: 'Short paper (EN)',
    link: SHORT_PAPER_EN_LINK
  },
  {
    title: 'Short paper (ZH)',
    link: SHORT_PAPER_ZH_LINK
  },
  {
    title: 'Docs',
    link: INTERLAY_KINTSUGI_DOCS_OVERVIEW_LINK
  },
  {
    title: 'Try Testnet',
    link: BRIDGE_APP_LINK
  }
];

interface CustomProps {
  appBarHeight: number;
}

const AppBar = ({
  appBarHeight,
  className,
  ...rest
}: CustomProps & React.ComponentPropsWithRef<'nav'>): JSX.Element => {
  return (
    <Disclosure
      as='nav'
      className={clsx(
        'shadow-md',
        'bg-kintsugiMidnight-600',
        className
      )}
      {...rest}>
      {({ open }) => (
        <>
          <div
            className={clsx(
              'container',
              'mx-auto',
              'px-4',
              'sm:px-6',
              'lg:px-8'
            )}>
            <div
              style={{ height: appBarHeight }}
              className={clsx(
                'flex',
                'justify-between'
              )}>
              <div
                className={clsx(
                  'flex',
                  'justify-between',
                  'w-full'
                )}>
                <div
                  className={clsx(
                    'flex-shrink-0',
                    'flex',
                    'items-center'
                  )}>
                  <KintsugiLogoIcon
                    width={50}
                    height={50} />
                </div>
                <div
                  className={clsx(
                    'hidden',
                    'sm:ml-6',
                    'sm:flex',
                    'sm:space-x-8'
                  )}>
                  {RESOURCES.map(resource => {
                    return (
                      <InterlayLink
                        key={resource.title}
                        href={resource.link}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={clsx(
                          'border-transparent',
                          'inline-flex',
                          'items-center',
                          'px-1',
                          'pt-1',
                          'border-b-2',
                          'text-lg',
                          'font-medium'
                        )}>
                        {resource.title}
                      </InterlayLink>
                    );
                  })}
                </div>
              </div>
              <div
                className={clsx(
                  '-mr-2',
                  'flex',
                  'items-center',
                  'sm:hidden'
                )}>
                {/* Mobile menu button */}
                <Disclosure.Button
                  className={clsx(
                    'inline-flex',
                    'items-center',
                    'justify-center',
                    'p-2',
                    'rounded-md',
                    'text-textPrimary',
                    'hover:text-textSecondary',
                    'hover:bg-kintsugiMidnight-400',
                    'focus:outline-none',
                    'focus:ring-2',
                    'focus:ring-inset',
                    'focus:ring-kintsugiAlto'
                  )}>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XIcon
                      className={clsx(
                        'block',
                        'h-6',
                        'w-6'
                      )}
                      aria-hidden='true' />
                  ) : (
                    <MenuIcon
                      className={clsx(
                        'block',
                        'h-6',
                        'w-6'
                      )}
                      aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className='sm:hidden'>
            <div
              className={clsx(
                'pt-2',
                'pb-3',
                'space-y-1',
                'bg-kintsugiMidnight-400'
              )}>
              {RESOURCES.map(resource => {
                return (
                  <InterlayLink
                    key={resource.title}
                    href={resource.link}
                    className={clsx(
                      'border-transparent',
                      'text-textSecondary',
                      'hover:border-kintsugiAlto',
                      'block',
                      'pl-3',
                      'pr-4',
                      'py-2',
                      'border-l-4',
                      'text-base',
                      'font-medium'
                    )}>
                    {resource.title}
                  </InterlayLink>
                );
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AppBar;
