
const truncateAddress = (address: string, fragmentLength = 5): string => {
  if (address.length <= fragmentLength * 2) {
    return address;
  }
  return `${address.slice(0, fragmentLength)}...${address.slice(-fragmentLength)}`;
};

export {
  truncateAddress
};
