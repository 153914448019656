
const INTERLAY_COMPANY_LINK = 'https://www.interlay.io';
const INTERLAY_TELEGRAM_LINK = 'https://t.me/interlay_community';
const INTERLAY_TWITTER_LINK = 'https://twitter.com/kintsugi_btc';
const INTERLAY_DISCORD_LINK = 'https://discord.gg/KgCYK3MKSf';
const INTERLAY_GITHUB_LINK = 'https://github.com/interlay';
const INTERLAY_MEDIUM_LINK = 'https://medium.com/interlay';
const INTERLAY_CROWDLOAN_LINK = 'https://crowdloan.interlay.io';
const INTERLAY_KINTSUGI_DOCS_OVERVIEW_LINK = 'https://docs.interlay.io/#/kintsugi/overview';
const INTERLAY_KINTSUGI_DOCS_TOKENOMICS_LINK = 'https://docs.interlay.io/#/kintsugi/tokenomics';
const SHORT_PAPER_EN_LINK = 'https://drive.google.com/file/d/1YpCWIPDqDmSiEiMCMF2nh5WN4uJY1MTD/view?usp=sharing';
const SHORT_PAPER_ZH_LINK = 'https://drive.google.com/file/d/1qs5Lom_2zMrorXadLnh9RL5JFgEsz31T/view?usp=sharing';
const BRIDGE_APP_LINK = 'https://bridge.interlay.io';
const KINTSUGI_TOKEN_ECONOMY_LINK =
  'https://raw.githubusercontent.com/interlay/whitepapers/master/Kintsugi_Token_Economy.pdf';
const TERMS_AND_CONDITIONS_LINK = 'https://drive.google.com/file/d/15QBCMYbIYwsgEfY9ZVQJ-2wlzJ01-D4w/view';
const KUSAMA_AUCTIONS_LINK = 'https://kusama.network/auctions/';
const XCLAIM_PAPER_LINK = 'https://eprint.iacr.org/2018/643.pdf';
const SCALING_BITCOIN_YOU_TUBE_LINK = 'https://www.youtube.com/watch?v=FI9cwksTrQs&t=6410s&ab_channel=ScalingBitcoin';
// eslint-disable-next-line max-len
const WEB3_FOUNDATION_LINK = 'https://github.com/w3f/General-Grants-Program/blob/master/grants/accepted_grant_applications.md#surfing_woman-wave-7---third-quarter-2020';
const ETH_CC_YOU_TUBE_LINK = 'https://www.youtube.com/watch?v=ZICMrQ9H_yM&ab_channel=EthereumFrance';

export {
  INTERLAY_COMPANY_LINK,
  INTERLAY_TELEGRAM_LINK,
  INTERLAY_TWITTER_LINK,
  INTERLAY_DISCORD_LINK,
  INTERLAY_GITHUB_LINK,
  INTERLAY_MEDIUM_LINK,
  INTERLAY_CROWDLOAN_LINK,
  INTERLAY_KINTSUGI_DOCS_OVERVIEW_LINK,
  INTERLAY_KINTSUGI_DOCS_TOKENOMICS_LINK,
  SHORT_PAPER_EN_LINK,
  SHORT_PAPER_ZH_LINK,
  BRIDGE_APP_LINK,
  KINTSUGI_TOKEN_ECONOMY_LINK,
  TERMS_AND_CONDITIONS_LINK,
  KUSAMA_AUCTIONS_LINK,
  XCLAIM_PAPER_LINK,
  SCALING_BITCOIN_YOU_TUBE_LINK,
  WEB3_FOUNDATION_LINK,
  ETH_CC_YOU_TUBE_LINK
};
