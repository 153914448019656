
import clsx from 'clsx';

import InterlayLink from 'components/UI/InterlayLink';

interface Props {
  error: Error;
  resetErrorBoundary?: () => void;
}

const handleRefresh = () => {
  window.location.reload();
};

const ErrorFallback = ({
  error,
  resetErrorBoundary
}: Props): JSX.Element => {
  return (
    <p
      className={clsx(
        'text-kintsugiCranberry',
        'space-x-1'
      )}>
      <span>Error: {error.message}.</span>
      <span>
        Please&nbsp;
        <InterlayLink
          onClick={resetErrorBoundary ?? handleRefresh}
          className={clsx(
            'underline',
            'cursor-pointer'
          )}>
          refresh
        </InterlayLink>
        .
      </span>
    </p>
  );
};

export default ErrorFallback;
