
import * as React from 'react';
import PropTypes from 'prop-types';
import jsonrpc from '@polkadot/types/interfaces/jsonrpc';
import { Keyring } from '@polkadot/ui-keyring/Keyring';
import type {
  DefinitionRpcExt,
  RegistryTypes
} from '@polkadot/types/types';
import {
  ApiPromise,
  WsProvider
} from '@polkadot/api';
import {
  web3Accounts,
  web3Enable
} from '@polkadot/extension-dapp';
import keyring from '@polkadot/ui-keyring';

import config from 'config';

const queryString = require('query-string');

enum KeyringState {
  Loading = 'LOADING',
  Ready = 'READY',
  Error = 'Error'
}

enum ApiState {
  ConnectInit = 'CONNECT_INIT',
  Connecting = 'CONNECTING',
  Ready = 'READY',
  Error = 'ERROR'
}

type Action =
  { type: 'CONNECT_INIT'; } |
  { type: 'CONNECT'; payload: ApiPromise; } |
  { type: 'CONNECT_SUCCESS' } |
  { type: 'CONNECT_ERROR'; payload: Error; } |
  { type: 'LOAD_KEYRING' } |
  { type: 'SET_KEYRING'; payload: Keyring; } |
  { type: 'KEYRING_ERROR' };
type Dispatch = (action: Action) => void;
type State = {
  socket: string;
  jsonrpc: Record<string, Record<string, DefinitionRpcExt>>;
  types: RegistryTypes;
  keyring: Keyring | null;
  keyringState: KeyringState | null;
  api: ApiPromise | null;
  apiError: Error | null;
  apiState: ApiState | null;
}
type SubstrateProviderProps = {
  children: React.ReactNode;
  socket?: string;
  types?: RegistryTypes;
};
interface SubstrateStateContextInterface {
  state: State;
  dispatch: Dispatch;
}

const parsedQuery = queryString.parse(window.location.search);
const connectedSocket = parsedQuery.rpc || config.PROVIDER_SOCKET;
// eslint-disable-next-line no-console
console.log(`Connected socket: ${connectedSocket}`);

///
// Initial state for `React.useReducer`
const INIT_STATE = {
  socket: connectedSocket,
  jsonrpc: {
    ...jsonrpc,
    ...config.RPC
  },
  types: config.types,
  keyring: null,
  keyringState: null,
  api: null,
  apiError: null,
  apiState: null
};

///
// Reducer function for `React.useReducer`
const substrateReducer = (state: State, action: Action): {
  socket: string;
  jsonrpc: Record<string, Record<string, DefinitionRpcExt>>;
  types: RegistryTypes;
  keyring: Keyring | null;
  keyringState: KeyringState | null;
  api: ApiPromise | null;
  apiError: Error | null;
  apiState: ApiState | null;
} => {
  switch (action.type) {
  case 'CONNECT_INIT':
    return {
      ...state,
      apiState: ApiState.ConnectInit
    };
  case 'CONNECT':
    return {
      ...state,
      api: action.payload,
      apiState: ApiState.Connecting
    };
  case 'CONNECT_SUCCESS':
    return {
      ...state,
      apiState: ApiState.Ready
    };
  case 'CONNECT_ERROR':
    return {
      ...state,
      apiState: ApiState.Error,
      apiError: action.payload
    };
  case 'LOAD_KEYRING':
    return {
      ...state,
      keyringState: KeyringState.Loading
    };
  case 'SET_KEYRING':
    return {
      ...state,
      keyring: action.payload,
      keyringState: KeyringState.Ready
    };
  case 'KEYRING_ERROR':
    return {
      ...state,
      keyring: null,
      keyringState: KeyringState.Error
    };
  default:
    throw new Error(`Unhandled action type: ${action}`);
  }
};

///
// Connecting to the Substrate node
const connect = (state: State, dispatch: Dispatch) => {
  const {
    apiState,
    socket,
    jsonrpc,
    types
  } = state;
  // We only want this function to be performed once
  if (apiState) return;

  dispatch({ type: 'CONNECT_INIT' });

  const provider = new WsProvider(socket);
  const _api = new ApiPromise({
    provider,
    types,
    rpc: jsonrpc
  });

  // Set listeners for disconnection and reconnection event.
  _api.on('connected', () => {
    dispatch({
      type: 'CONNECT',
      payload: _api
    });
    // `ready` event is not emitted upon reconnection and is checked explicitly here.
    _api.isReady.then(_api => {
      dispatch({ type: 'CONNECT_SUCCESS' });
      // Keyring accounts were not being loaded properly because the `api` needs to first load
      // the WASM file used for `sr25519`. Loading accounts at this point follows the recommended pattern:
      // https://polkadot.js.org/docs/ui-keyring/start/init/#using-with-the-api
      loadAccounts(state, dispatch);
    });
  });
  _api.on('ready', () => dispatch({ type: 'CONNECT_SUCCESS' }));
  _api.on('error', (error: Error) => dispatch({
    type: 'CONNECT_ERROR',
    payload: error
  }));
};

///
// Loading accounts from dev and polkadot-js extension
let loadAccts = false;
const loadAccounts = (state: State, dispatch: Dispatch) => {
  const asyncLoadAccounts = async () => {
    dispatch({ type: 'LOAD_KEYRING' });
    try {
      await web3Enable(config.APP_NAME);
      let allAccounts = await web3Accounts();
      allAccounts = allAccounts.map(({
        address,
        meta
      }) => ({
        address,
        meta: {
          ...meta,
          name: `${meta.name} (${meta.source})`
        }
      }));
      keyring.loadAll(
        {
          isDevelopment: config.DEVELOPMENT_KEYRING
        },
        allAccounts
      );
      dispatch({
        type: 'SET_KEYRING',
        payload: keyring
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[asyncLoadAccounts] error.message => ', error.message);
      dispatch({ type: 'KEYRING_ERROR' });
    }
  };

  const { keyringState } = state;
  // If `keyringState` is not null `asyncLoadAccounts` is running.
  if (keyringState) return;
  // If `loadAccts` is true, the `asyncLoadAccounts` has been run once.
  if (loadAccts) return dispatch({ type: 'SET_KEYRING', payload: keyring });

  // This is the heavy duty work
  loadAccts = true;
  asyncLoadAccounts();
};

const SubstrateStateContext = React.createContext<
  SubstrateStateContextInterface | undefined
>(undefined);

const SubstrateProvider = ({
  children,
  socket,
  types
}: SubstrateProviderProps): JSX.Element => {
  const [state, dispatch] = React.useReducer<(state: State, action: Action) => {
    socket: string;
    jsonrpc: Record<string, Record<string, DefinitionRpcExt>>;
    types: RegistryTypes;
    keyring: Keyring | null;
    keyringState: KeyringState | null;
    api: ApiPromise | null;
    apiError: Error | null;
    apiState: ApiState | null;
  }>(substrateReducer, {
      ...INIT_STATE,
      // Filtering props and merge with default param value
      socket: socket ?? INIT_STATE.socket,
      types: types ?? INIT_STATE.types
    });
  connect(state, dispatch);

  const value = {
    state,
    dispatch
  };

  return (
    <SubstrateStateContext.Provider value={value}>
      {children}
    </SubstrateStateContext.Provider>
  );
};

// prop-type checking
SubstrateProvider.propTypes = {
  socket: PropTypes.string,
  types: PropTypes.object
};

const useSubstrate = (): SubstrateStateContextInterface => {
  const context = React.useContext(SubstrateStateContext);
  if (context === undefined) {
    throw new Error('useSubstrate must be used within a SubstrateProvider!');
  }
  return context;
};

export {
  KeyringState,
  ApiState
};

export {
  SubstrateProvider,
  useSubstrate
};
