
import { encodeAddress } from '@polkadot/util-crypto';

import { hashAddress } from 'utils/helpers/referral';
import { Contribution } from 'services/sub-query';

const getValidMemos = (contributions: Array<Contribution>): Array<string> => {
  return contributions.map(contribution => hashAddress(encodeAddress(contribution.address, 42)));
};

export {
  getValidMemos
};
