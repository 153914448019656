
import clsx from 'clsx';

// TODO: could use SVG versions
import IosgLogo from 'assets/img/backers/iosg-logo.png';
import LaunchubLogo from 'assets/img/backers/launchub-logo.png';
import BlockchainLogo from 'assets/img/backers/blockchain-logo.png';
import Kr1Logo from 'assets/img/backers/kr1-logo.png';
import HypersphereLogo from 'assets/img/backers/hypersphere-logo.png';
import CmsLogo from 'assets/img/backers/cms-logo.png';
import ZeePrimeLogo from 'assets/img/backers/zee-prime-logo.png';

const BackedBySection = (): JSX.Element => (
  <section className='space-y-10'>
    <h1
      className={clsx(
        'text-4xl',
        'text-center',
        'font-abcWhyte'
      )}>
      Backed By
    </h1>
    <div
      className={clsx(
        'bg-kintsugiMidnight-300',
        'shadow',
        'md:rounded-lg',
        'p-5',
        'grid',
        'grid-cols-1',
        'sm:grid-cols-2',
        'md:grid-cols-4',
        'gap-10'
      )}>
      <img
        className={clsx(
          'max-h-44',
          'm-auto'
        )}
        src={IosgLogo}
        alt='Iosg Ventures' />
      <img
        className={clsx(
          'max-h-44',
          'm-auto'
        )}
        src={LaunchubLogo}
        alt='Launchub Ventures' />
      <img
        className={clsx(
          'max-h-44',
          'm-auto'
        )}
        src={BlockchainLogo}
        alt='Blockchain Ventures' />
      <img
        className={clsx(
          'max-h-44',
          'm-auto'
        )}
        src={Kr1Logo}
        alt='KR1 PLC' />
      <img
        className={clsx(
          'max-h-44',
          'm-auto'
        )}
        src={HypersphereLogo}
        alt='Hypersphere' />
      <img
        className={clsx(
          'max-h-44',
          'm-auto'
        )}
        src={CmsLogo}
        alt='CMS' />
      <img
        className={clsx(
          'max-h-44',
          'm-auto'
        )}
        src={ZeePrimeLogo}
        alt='Zee Prime Capital' />
    </div>
  </section>
);

export default BackedBySection;
