import axios from 'axios';
import {
  hexAddPrefix,
  isHex
} from '@polkadot/util';
import { KusamaAmount } from '@interlay/monetary-js';

import { FUND_ID } from 'utils/helpers/constants-config';

const SUBQUERY_PER_PAGE = 100; // apparently

interface RawCrowdloan {
  id: string;
  cap: string;
  raised: string;
  lockedExpiredBlock: number;
  parachain: {
    paraId: number;
    manager: string;
  }
}

interface RawContribution {
  id: string;
  account: string;
  amount: string;
  createdAt: string;
  blockNum: number;
  memo: string | undefined;
}

interface crowdloanQueryResponse {
  data: {
    crowdloan: RawCrowdloan;
    contributions: {
      totalCount: number;
    };
  };
}

interface contributionQueryResponse {
  data: {
    contributions: {

      nodes: RawContribution[];
    };
  };
}

class CrowdloanSummary {
  constructor(
    public id: string,
    public cap: KusamaAmount,
    public raised: KusamaAmount,
    public lockedExpiredBlock: number
  ) {}
}

class Contribution {
  constructor(
    public address: string,
    public amountKSM: KusamaAmount,
    public date: Date,
    public referral?: string
  ) {}
}

class Crowdloan {
  constructor(
    public crowdloan: CrowdloanSummary,
    public contributions: Contribution[],
    public totalCount: number
  ) {}
}

const CROWDLOAN_QUERY = `
  query Crowdloan($fundId: String!) {
    crowdloan (id: $fundId) {
      id
      cap
      raised
      lockExpiredBlock
      parachain {
        paraId
        manager
      }
    }
    contributions (
      filter: {
        fundId: {
          equalTo: $fundId
        },
      },
      orderBy: BLOCK_NUM_ASC,
    ) {
      totalCount
    }
  }
`;

const CONTIBUTIONS_QUERY = `
  query Contributions($fundId: String!, $offset: Int!) {
    contributions (
      filter: {
        fundId: {
          equalTo: $fundId
        },
      },
      orderBy: BLOCK_NUM_ASC,
      offset: $offset
    ) {
      nodes {
        id
        account
        amount
        createdAt
        blockNum
        memo
      }
    }
  }
`;
// TODO: should use https://create-react-app.dev/docs/adding-relay/
async function getCrowdloan(): Promise<Crowdloan> {
  const res = await axios.post<crowdloanQueryResponse>('', {
    query: CROWDLOAN_QUERY,
    variables: {
      fundId: FUND_ID
    }
  });
  const rawCrowdloan = res.data.data.crowdloan;

  const crowdloanSummary = new CrowdloanSummary(
    rawCrowdloan.id,
    KusamaAmount.from.Planck(rawCrowdloan.cap),
    KusamaAmount.from.Planck(rawCrowdloan.raised),
    rawCrowdloan.lockedExpiredBlock
  );

  const totalCount = res.data.data.contributions.totalCount;

  const contributionPages = Math.ceil(totalCount / SUBQUERY_PER_PAGE);
  // eslint-disable-next-line no-debugger
  const contributionQueries = [];
  for (let i = 0; i < contributionPages; i++) {
    contributionQueries.push(axios.post<contributionQueryResponse>('', {
      query: CONTIBUTIONS_QUERY,
      variables: {
        fundId: FUND_ID,
        offset: i * SUBQUERY_PER_PAGE
      }
    }));
  }

  const rawContributionsResponses = await Promise.all(contributionQueries);
  const rawContributions = rawContributionsResponses.reduce(
    (acc, res) => acc.concat(res.data.data.contributions.nodes),
    [] as RawContribution[]
  );

  const contributions = rawContributions.map(({
    account,
    amount,
    createdAt,
    memo
  }: RawContribution) => {
    const amountKSM = KusamaAmount.from.Planck(amount);
    const referralCode = memo ?
      (isHex(hexAddPrefix(memo)) && hexAddPrefix(memo).length === 66) ?
        memo :
        undefined :
      undefined;

    return new Contribution(account, amountKSM, new Date(createdAt), referralCode);
  });

  return new Crowdloan(crowdloanSummary, contributions, totalCount);
}

const CROWDLOAN_DATA_QUERY_KEY = 'crowdloan-data-query-key';
const LEGAL_TEXT_QUERY_KEY = 'legal-text-query-key';

export type {
  Crowdloan,
  Contribution
};

export {
  getCrowdloan,
  CROWDLOAN_DATA_QUERY_KEY,
  LEGAL_TEXT_QUERY_KEY
};
