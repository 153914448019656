
import { useQuery } from 'react-query';
import {
  withErrorBoundary,
  useErrorHandler
} from 'react-error-boundary';
import { encodeAddress } from '@polkadot/util-crypto';
import clsx from 'clsx';
import { KusamaAmount } from '@interlay/monetary-js';

import Panel from 'components/Panel';
import ErrorFallback from 'components/ErrorFallback';
import * as config from 'utils/helpers/constants-config';
import { hashAddress } from 'utils/helpers/referral';
import { truncateAddress } from 'utils/helpers/address';
import {
  getCrowdloan,
  Crowdloan,
  CROWDLOAN_DATA_QUERY_KEY
} from 'services/sub-query';

const RANKING_COUNT = 10;

const ReferralsLeaderboard = (): JSX.Element => {
  const {
    isIdle: crowdloanDataIdle,
    isLoading: crowdloanDataLoading,
    data: crowdloanData,
    error: crowdloanDataError
  } = useQuery<Crowdloan, Error>([CROWDLOAN_DATA_QUERY_KEY], getCrowdloan);
  useErrorHandler(crowdloanDataError);

  if (crowdloanDataIdle || crowdloanDataLoading) {
    // TODO: should use skeleton loaders
    return <>Loading...</>;
  }
  if (crowdloanData === undefined) {
    throw new Error('Something went wrong!');
  }

  const contributions = crowdloanData.contributions;
  const memoToAddressMap = new Map(
    contributions.map(contribution => {
      return [hashAddress(encodeAddress(contribution.address, 42)), contribution.address];
    })
  );
  const referredContributions = contributions.filter(contribution =>
    contribution.referral && memoToAddressMap.get(contribution.referral)
  );
  const memoToAmountReferredMap: Map<string, KusamaAmount> = new Map();
  for (const referredContribution of referredContributions) {
    if (referredContribution.referral) {
      const current = memoToAmountReferredMap.get(referredContribution.referral) || KusamaAmount.zero;
      memoToAmountReferredMap.set(referredContribution.referral, current.add(referredContribution.amountKSM));
    }
  }
  const sortedEntries =
    [...memoToAmountReferredMap.entries()]
      .sort((a, b) => b[1].sub(a[1]).toBig().toNumber());

  const leaderboard =
    sortedEntries
      .slice(0, Math.min(RANKING_COUNT, sortedEntries.length))
      .map(([memo, amount]) => ({
        address: truncateAddress(memoToAddressMap.get(memo) || 'N/A'), // "N/A" should never happen
        amountReferred: `${Number(amount.toHuman()).toLocaleString()} KSM`,
        referralReward:
          `${Number(amount.mul(config.BASE_REWARD).mul(config.REFERRAL_REWARD).toHuman()).toLocaleString()} KINT`
      }));

  return (
    <div className='space-y-10'>
      <h1
        className={clsx(
          'text-center',
          'text-4xl',
          'font-abcWhyte'
        )}>
        Kintsugi Community Referrals
      </h1>
      <Panel
        className={clsx(
          'px-4',
          'py-5',
          'sm:p-6'
        )}>
        <table
          className={clsx(
            'table-fixed',
            'w-full',
            'divide-y',
            'text-sm',
            'sm:text-base'
          )}>
          <thead>
            <tr className='h-10'>
              <th className='w-1/12'>
                Rank
              </th>
              <th className='w-4/12'>
                Account
              </th>
              <th className='w-4/12'>
                Referred
              </th>
              <th className='w-3/12'>
                Referral Bonus
              </th>
            </tr>
          </thead>
          <tbody className='text-center'>
            {leaderboard.map((item, index) => {
              const additionalStyles = index === 0 ?
                ['bg-yellow-300', 'bg-opacity-50'] :
                index === 1 ?
                  ['bg-gray-100', 'bg-opacity-40'] :
                  index === 2 ?
                    ['bg-yellow-500', 'bg-opacity-30'] :
                    [];

              return (
                <tr
                  key={index}
                  className={clsx(
                    ...additionalStyles,
                    'h-12'
                  )}>
                  <td>{index === 0 ? '1st' : index === 1 ? '2nd' : index === 2 ? '3rd' : `${index + 1}th`}</td>
                  <td>{item.address}</td>
                  <td>{item.amountReferred}</td>
                  <td>{item.referralReward}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Panel>
    </div>
  );
};

export default withErrorBoundary(ReferralsLeaderboard, {
  FallbackComponent: ErrorFallback,
  onReset: () => {
    window.location.reload();
  }
});
