import { KusamaAmount } from '@interlay/monetary-js';
import { encodeAddress } from '@polkadot/util-crypto';

import {
  Contribution
} from 'services/sub-query';
import { bifrostContributions } from 'utils/data/bifrost-contributions';

const getBifrostContributions = (address: string): Array<Contribution> => {
  // NOTE: Address is encoded before being passed into this function, so we only need to
  // encode the Bifrost addresses here.
  const contributions = bifrostContributions.filter(
    contribution => encodeAddress(contribution.account, 2) === address
  );

  if (!contributions) return [];

  const mappedContributions = contributions.map(contribution => {
    return {
      address: contribution.account,
      amountKSM: KusamaAmount.from.Planck(contribution.balance),
      date: new Date(contribution.blockTimestamp)
    };
  });

  return mappedContributions;
};

export { getBifrostContributions };
