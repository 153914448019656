import { web3FromSource } from '@polkadot/extension-dapp';
import { KeyringPair } from '@polkadot/keyring/types';
import { stringToHex } from '@polkadot/util';

const signMessage = async (accountPair: KeyringPair, message: string): Promise<string | undefined> => {
  if (!accountPair) {
    return;
  }

  const injector = await web3FromSource(accountPair.meta.source as string);

  const signRaw = injector?.signer?.signRaw;

  if (!signRaw) {
    return;
  }

  const { signature } = await signRaw({
    address: accountPair.address,
    data: stringToHex(message),
    type: 'bytes'
  });

  return signature;
};

export { signMessage };
