
import * as React from 'react';
import { KeyringPair } from '@polkadot/keyring/types';
import Identicon from '@polkadot/react-identicon';
import clsx from 'clsx';
import { useSubstrate } from 'substrate-lib';

import Select, {
  SelectButton,
  SelectOptions,
  SelectOption,
  SelectBody,
  SelectCheck,
  SelectText
} from 'components/Select';

interface Props {
  selectedAccountAddress: string | undefined;
  setSelectedAccountAddress: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface KeyringOption {
  id: string;
  name: string;
  value: string;
}

const AccountSelect = ({
  selectedAccountAddress,
  setSelectedAccountAddress
}: Props): JSX.Element | null => {
  const { state: { keyring } } = useSubstrate();
  if (!keyring) {
    throw new Error('Something went wrong!');
  }

  // Get the list of accounts we possess the private key for
  const keyringOptions: Array<KeyringOption> = keyring.getPairs().map((account: KeyringPair) => ({
    id: account.address,
    name: (account.meta.name as string).toUpperCase(),
    value: account.address
  }));

  React.useEffect(() => {
    if (!keyringOptions) return;
    if (!setSelectedAccountAddress) return;

    if (!selectedAccountAddress) {
      setSelectedAccountAddress(keyringOptions[0]?.value);
    }
  }, [
    selectedAccountAddress,
    keyringOptions,
    setSelectedAccountAddress
  ]);

  const handleChange = (newKeyringOption: KeyringOption) => {
    setSelectedAccountAddress(newKeyringOption.value);
  };

  const selectedKeyringOption =
    keyringOptions.find((keyringOption: KeyringOption) => keyringOption.value === selectedAccountAddress);

  if (!selectedAccountAddress) return null;

  return (
    <Select
      value={selectedKeyringOption}
      onChange={handleChange}>
      {({ open }) => (
        <>
          <SelectBody>
            <SelectButton className='!w-80'>
              <span
                className={clsx(
                  'flex',
                  'items-center',
                  'space-x-3'
                )}>
                <Identicon
                  value={selectedKeyringOption ? selectedKeyringOption.value : ''}
                  size={32} />
                <SelectText>
                  {selectedKeyringOption ? selectedKeyringOption.name : ''}
                </SelectText>
              </span>
            </SelectButton>
            <SelectOptions open={open}>
              {keyringOptions.map((keyringOption: KeyringOption) => {
                return (
                  <SelectOption
                    key={keyringOption.id}
                    value={keyringOption}>
                    {({
                      selected,
                      active
                    }) => (
                      <>
                        <div
                          className={clsx(
                            'flex',
                            'items-center',
                            'space-x-3'
                          )}>
                          <Identicon
                            value={keyringOption.value}
                            size={32} />
                          <SelectText selected={selected}>
                            {keyringOption.name}
                          </SelectText>
                        </div>
                        {selected ? (
                          <SelectCheck active={active} />
                        ) : null}
                      </>
                    )}
                  </SelectOption>
                );
              })}
            </SelectOptions>
          </SelectBody>
        </>
      )}
    </Select>
  );
};

export default AccountSelect;
