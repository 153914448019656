
import axios from 'axios';
import axiosRetry from 'axios-retry';

import config from 'config';

function initAxios(): void {
  axios.defaults.baseURL = config.SUBQUERY_URL;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axiosRetry(axios, {
    retries: 10,
    retryDelay: () => 600,
    retryCondition: error => error.response?.status === 429
  });
}

export default initAxios;
