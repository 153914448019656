
import * as React from 'react';
import {
  withErrorBoundary,
  useErrorHandler
} from 'react-error-boundary';
import type { AccountInfo } from '@polkadot/types/interfaces/system';
import clsx from 'clsx';
import { KusamaAmount } from '@interlay/monetary-js';
import { useSubstrate } from 'substrate-lib';

import ErrorFallback from 'components/ErrorFallback';
import InfoMessage from 'components/InfoMessage';

interface Props {
  accountAddress: string;
}

const BalanceAnnotation = ({ accountAddress }: Props): JSX.Element => {
  const { state: { api } } = useSubstrate();
  if (!api) {
    throw new Error('Something went wrong!');
  }
  const handleError = useErrorHandler();
  const [accountBalance, setAccountBalance] = React.useState<KusamaAmount>();

  // When account address changes, update subscriptions
  React.useEffect(() => {
    if (!accountAddress) return;
    if (!api) return;
    if (!handleError) return;

    let unsubscribe: any;

    // If the user has selected an address, create a new subscription
    api.query.system.account(accountAddress, (balance: AccountInfo) => {
      setAccountBalance(KusamaAmount.from.Planck(balance.data.free.toString()));
    })
      // TODO: should type properly
      .then((theUnsubscribe: any) => {
        unsubscribe = theUnsubscribe;
      })
      .catch((error: Error) => {
        handleError(error);
      });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [
    api,
    accountAddress,
    handleError
  ]);

  return (
    <>
      {accountBalance !== undefined && (
        <InfoMessage
          className={clsx(
            'max-w-xs',
            'text-lg'
          )}>
          Available KSM: {Number(accountBalance.str.KSM()).toLocaleString()}
        </InfoMessage>
      )}
    </>
  );
};

export default withErrorBoundary(BalanceAnnotation, {
  FallbackComponent: ErrorFallback,
  onReset: () => {
    window.location.reload();
  }
});
