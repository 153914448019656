
import clsx from 'clsx';

import InterlayLink from 'components/UI/InterlayLink';
import {
  XCLAIM_PAPER_LINK,
  SCALING_BITCOIN_YOU_TUBE_LINK,
  WEB3_FOUNDATION_LINK,
  ETH_CC_YOU_TUBE_LINK
} from 'config/links';
import { ReactComponent as InformalLogoIcon } from 'assets/img/audits/informal-logo.svg';
import { ReactComponent as ScalingBitcoinLogoIcon } from 'assets/img/audits/scaling-bitcoin-logo.svg';
import { ReactComponent as EthCCLogoIcon } from 'assets/img/audits/eth-cc-logo.svg';
import {
  ReactComponent as Web3FoundationGrantsProgramLogoIcon
} from 'assets/img/audits/web3-foundation-grants-program-logo.svg';

const AuditedAndPeerReviewedSection = (): JSX.Element => (
  <section className='space-y-10'>
    <h1
      className={clsx(
        'text-4xl',
        'text-center',
        'font-abcWhyte'
      )}>
      Audited & Peer-reviewed
    </h1>
    <div
      className={clsx(
        'bg-kintsugiMidnight',
        'shadow',
        'md:rounded-lg',
        'p-5',
        'text-lg',
        'text-justify',
        'grid',
        'grid-cols-1',
        'md:grid-cols-2',
        'divide-y',
        'md:divide-y-0',
        'md:divide-x'
      )}>
      <div
        className={clsx(
          'p-6',
          'flex',
          'flex-col',
          'justify-center'
        )}>
        <InformalLogoIcon className='w-full' />
      </div>
      <div
        className={clsx(
          'p-6',
          'space-y-5'
        )}>
        <div
          className={clsx(
            'font-bold',
            'space-y-3'
          )}>
          <p>
            interBTC is backed by peer-reviewed, top tier research...
          </p>
          <p>
            <InterlayLink
              className='underline'
              href={XCLAIM_PAPER_LINK}
              target='_blank'
              rel='noopener noreferrer'>
              Read the XCLAIM paper
            </InterlayLink>
          </p>
          <p>
            ... and assessed by the community.
          </p>
        </div>
        <div
          className={clsx(
            'flex',
            'justify-between',
            'items-center',
            'space-x-5',
            'overflow-x-auto'
          )}>
          <InterlayLink
            href={SCALING_BITCOIN_YOU_TUBE_LINK}
            target='_blank'
            rel='noopener noreferrer'>
            <ScalingBitcoinLogoIcon
              width={80}
              height={89.186} />
          </InterlayLink>
          <InterlayLink
            href={ETH_CC_YOU_TUBE_LINK}
            target='_blank'
            rel='noopener noreferrer'>
            <EthCCLogoIcon
              width={45.25}
              height={80} />
          </InterlayLink>
          <InterlayLink
            href={WEB3_FOUNDATION_LINK}
            target='_blank'
            rel='noopener noreferrer'>
            <Web3FoundationGrantsProgramLogoIcon
              className={clsx(
                'fill-current',
                'text-white'
              )}
              width={125.4}
              height={53.33} />
          </InterlayLink>
        </div>
      </div>
    </div>
  </section>
);

export default AuditedAndPeerReviewedSection;
