
import clsx from 'clsx';

import { ReactComponent as SpinIcon } from 'assets/img/icons/spin.svg';

interface FullLoadingSpinnerCustomProps {
  text?: string;
}

const FullLoadingSpinner = ({
  text,
  className,
  ...rest
}: FullLoadingSpinnerCustomProps & React.ComponentPropsWithRef<'div'>): JSX.Element => (
  <div
    className={clsx(
      'flex',
      'justify-center',
      'items-center',
      'absolute',
      'top-0',
      'left-0',
      'w-full',
      'h-full',
      'bg-kintsugiMidnight',
      className
    )}
    {...rest}>
    <div
      className={clsx(
        'inline-flex',
        'flex-col',
        'items-center',
        'space-y-2'
      )}>
      <SpinIcon
        className={clsx(
          'text-kintsugiAlto',
          'animate-spin',
          'w-9',
          'h-9'
        )} />
      {text && (
        <p
          className={clsx(
            'font-medium',
            'text-sm'
          )}>
          {text}
        </p>
      )}
    </div>
  </div>
);

export default FullLoadingSpinner;
