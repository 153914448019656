import {
  withErrorBoundary,
  useErrorHandler
} from 'react-error-boundary';
import { useQuery } from 'react-query';
import { encodeAddress } from '@polkadot/util-crypto';
import { KeyringPair } from '@polkadot/keyring/types';
import Big from 'big.js';
import clsx from 'clsx';
import {
  ExchangeRate,
  Kintsugi,
  KintsugiUnit,
  Kusama,
  KusamaAmount,
  KusamaUnit
} from '@interlay/monetary-js';

import Panel from 'components/Panel';
import ClaimKintForm from 'containers/ClaimKintForm';
import ErrorFallback from 'components/ErrorFallback';
import {
  EARLY_BIRD_BONUS_END,
  BASE_REWARD,
  EARLY_BIRD_REWARD,
  REFERRAL_REWARD,
  SUCCESS_BONUS_1,
  SUCCESS_BONUS_2,
  SUCCESS_BONUS_3_GROSS,
  SUCCESS_BONUS_1_THRESHOLD,
  SUCCESS_BONUS_2_THRESHOLD,
  SUCCESS_BONUS_3_THRESHOLD
} from 'utils/helpers/constants-config';
import { hashAddress } from 'utils/helpers/referral';
import {
  getCrowdloan,
  Contribution,
  Crowdloan,
  CROWDLOAN_DATA_QUERY_KEY
} from 'services/sub-query';
import getStrongSupporterBonusPercentage from 'utils/helpers/get-strong-supporter-bonus-percentage';
import { getValidMemos } from 'utils/helpers/contributions';
import { getBifrostContributions } from 'utils/helpers/get-bifrost-contributions';

const getSumOfContributions = (contributions: Array<Contribution>): KusamaAmount =>
  contributions.reduce(
    (sum, contribution) => sum.add(contribution.amountKSM),
    KusamaAmount.zero
  );

interface Props {
  accountPair: KeyringPair;
}

const ContributionStats = ({ accountPair }: Props): JSX.Element => {
  const {
    isIdle: crowdloanDataIdle,
    isLoading: crowdloanDataLoading,
    data: crowdloanData,
    error: crowdloanDataError
  } = useQuery<Crowdloan, Error>([CROWDLOAN_DATA_QUERY_KEY], getCrowdloan);

  useErrorHandler(crowdloanDataError);

  if (crowdloanDataIdle || crowdloanDataLoading) {
    // TODO: should use skeleton loaders
    return <>Loading...</>;
  }

  if (crowdloanData === undefined) {
    throw new Error('Something went wrong!');
  }

  const sumOfContributions = getSumOfContributions(crowdloanData.contributions);
  const validMemos = getValidMemos(crowdloanData.contributions);

  const address = encodeAddress(accountPair.addressRaw, 2);
  const referralMemo = hashAddress(accountPair.address);
  const contributions = crowdloanData.contributions;

  // Total contributed
  const crowdloanContributions = contributions.filter(contribution => contribution.address === address);

  // Check for Bifrost contributions
  const bifrostContributions = getBifrostContributions(address);

  const myContributions = crowdloanContributions.concat(bifrostContributions);

  // Total eligible for early bird
  const myEarlyBirdContributions =
    myContributions.filter(contribution => contribution.date.getTime() <= EARLY_BIRD_BONUS_END);

  // Total contributed by being referred by others (hence eligible for 5% paradrop)
  const myReferredContributions = myContributions.filter(
    contribution => validMemos?.includes(contribution.referral || '')
  );

  // Total contributions from others being referred by you
  const myReferrals = contributions.filter(contribution => contribution.referral === referralMemo);

  const sumOfMyContributions = getSumOfContributions(myContributions);
  const sumOfMyEarlyBirdContributions = getSumOfContributions(myEarlyBirdContributions);
  const sumOfMyReferredContributions = getSumOfContributions(myReferredContributions);
  const sumOfMyReferrals = getSumOfContributions(myReferrals);

  const successBonusRate =
    sumOfContributions.gte(SUCCESS_BONUS_1_THRESHOLD) ?
      sumOfContributions.gte(SUCCESS_BONUS_2_THRESHOLD) ?
        sumOfContributions.gte(SUCCESS_BONUS_3_THRESHOLD) ?
          SUCCESS_BONUS_3_GROSS :
          SUCCESS_BONUS_2 :
        SUCCESS_BONUS_1 : 0;

  const ksmToKintBase =
    new ExchangeRate<
      Kusama,
      KusamaUnit,
      Kintsugi,
      KintsugiUnit
    >(Kusama, Kintsugi, new Big(BASE_REWARD));

  const ksmToKintSuccess =
    new ExchangeRate<
      Kusama,
      KusamaUnit,
      Kintsugi,
      KintsugiUnit
    >(Kusama, Kintsugi, new Big(successBonusRate));

  const baseReward = ksmToKintBase.toCounter(sumOfMyContributions);
  const earlyBirdBonus = ksmToKintBase.toCounter(sumOfMyEarlyBirdContributions).mul(EARLY_BIRD_REWARD);
  const referredBonus = ksmToKintBase.toCounter(sumOfMyReferredContributions).mul(REFERRAL_REWARD);
  const referralBonus = ksmToKintBase.toCounter(sumOfMyReferrals).mul(REFERRAL_REWARD);

  const successBonus = ksmToKintSuccess.toCounter(sumOfMyContributions);

  const strongSupporterBonusPercentage =
    getStrongSupporterBonusPercentage(sumOfMyContributions);
  const strongSupporterBonus = baseReward.mul(strongSupporterBonusPercentage / 100);

  const totalReward =
    baseReward
      .add(earlyBirdBonus)
      .add(referredBonus)
      .add(referralBonus)
      .add(successBonus)
      .add(strongSupporterBonus);

  return (
    <Panel
      className={clsx(
        'space-y-4',
        'px-4',
        'py-5',
        'sm:p-6'
      )}>
      <ul
        className={clsx(
          'grid',
          'grid-cols-1',
          'md:grid-cols-3',
          'gap-2'
        )}>
        <li>
          <h4
            className={clsx(
              'text-md',
              'text-center'
            )}>
            Your total contributions
          </h4>
          <span
            className={clsx(
              'text-4xl',
              'text-center',
              'block'
            )}>
            {Number(sumOfMyContributions.toHuman()).toLocaleString()} KSM
          </span>
        </li>
        <li>
          <h4
            className={clsx(
              'text-md',
              'text-center'
            )}>
            Your total referrals
          </h4>
          <span
            className={clsx(
              'text-4xl',
              'text-center',
              'block'
            )}>
            {Number(sumOfMyReferrals.toHuman()).toLocaleString()} KSM
          </span>
        </li>
        <li>
          <h4
            className={clsx(
              'text-md',
              'text-center'
            )}>
            Your total paradrop (estimate)
          </h4>
          <span
            className={clsx(
              'text-4xl',
              'text-center',
              'block'
            )}>
            {Number(totalReward.toHuman()).toLocaleString()} KINT
          </span>
        </li>
      </ul>
      {!sumOfMyContributions.isZero() && (
        <>
          <hr
            className={clsx(
              'border-t-1',
              'border-textSecondary'
            )} />
          <ul
            className={clsx(
              'grid',
              'grid-cols-1',
              'md:grid-cols-3',
              'gap-2'
            )}>
            <li>
              <h5
                className={clsx(
                  'text-sm',
                  'text-center'
                )}>
                Base paradrop
              </h5>
              <span
                className={clsx(
                  'text-2xl',
                  'text-center',
                  'block'
                )}>
                {baseReward.toHuman().toLocaleString()} KINT
              </span>
            </li>
            <li>
              <h5
                className={clsx(
                  'text-sm',
                  'text-center'
                )}>
                Early bird bonus
              </h5>
              <span
                className={clsx(
                  'text-2xl',
                  'text-center',
                  'block'
                )}>
                {earlyBirdBonus.toHuman().toLocaleString()} KINT
              </span>
            </li>
            <li>
              <h5
                className={clsx(
                  'text-sm',
                  'text-center'
                )}>
                Strong supporter bonus
              </h5>
              <span
                className={clsx(
                  'text-2xl',
                  'text-center',
                  'block'
                )}>
                {strongSupporterBonus.toHuman().toLocaleString()} KINT
              </span>
            </li>
            <li>
              <h5
                className={clsx(
                  'text-sm',
                  'text-center'
                )}>
                Referral bonus (your referral link)
              </h5>
              <span
                className={clsx(
                  'text-2xl',
                  'text-center',
                  'block'
                )}>
                {referralBonus.toHuman().toLocaleString()} KINT
              </span>
            </li>
            <li>
              <h5
                className={clsx(
                  'text-sm',
                  'text-center'
                )}>
                Referral bonus (using someone&apos;s referral link)
              </h5>
              <span
                className={clsx(
                  'text-2xl',
                  'text-center',
                  'block'
                )}>
                {referredBonus.toHuman().toLocaleString()} KINT
              </span>
            </li>
            <li>
              <h5
                className={clsx(
                  'text-sm',
                  'text-center'
                )}>
                Success bonus
              </h5>
              <span
                className={clsx(
                  'text-2xl',
                  'text-center',
                  'block'
                )}>
                {successBonus.toHuman().toLocaleString()} KINT
              </span>
            </li>
          </ul>
          <hr
            className={clsx(
              'border-t-1',
              'border-textSecondary'
            )} />
          <ClaimKintForm accountPair={accountPair} />
        </>
      )}
    </Panel>
  );
};

export default withErrorBoundary(ContributionStats, {
  FallbackComponent: ErrorFallback,
  onReset: () => {
    window.location.reload();
  }
});
