import clsx from 'clsx';

import KintsugiCranberryContainedButton from 'components/buttons/KintsugiCranberryContainedButton';
import InterlayLink from 'components/UI/InterlayLink';

interface Props {
  href: string;
  children: React.ReactNode;
}

const LinkButton = ({
  href,
  children
}: Props): JSX.Element => (
  <InterlayLink
    className={clsx(
      'hover:no-underline'
    )}
    target='_blank'
    rel='noopener noreferrer'
    href={href}>
    <KintsugiCranberryContainedButton
      className={clsx(
        'h-14',
        'text-xl'
      )}>
      {children}
    </KintsugiCranberryContainedButton>
  </InterlayLink>
);

export default LinkButton;
