
import { KeyringPair } from '@polkadot/keyring/types';
import clsx from 'clsx';

import AccountSelect from 'containers/AccountSelect';
import BalanceAnnotation from 'containers/BalanceAnnotation';
import ContributionStats from 'containers/ContributionStats';
import PolkadotJSExtensionAnnotation from 'components/PolkadotJSExtensionAnnotation';

interface Props {
  accountPair: KeyringPair | undefined;
  selectedAccountAddress: string | undefined;
  setSelectedAccountAddress: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const SupportTheKintsugiCrowdloanSection = ({
  accountPair,
  selectedAccountAddress,
  setSelectedAccountAddress
}: Props): JSX.Element => (
  <section
    className={clsx(
      'space-y-10'
    )}>
    {!selectedAccountAddress && (
      <PolkadotJSExtensionAnnotation />
    )}
    <div
      className={clsx(
        'space-y-6',
        'md:space-y-0',
        'md:flex',
        'md:items-start',
        'md:justify-end'
      )}>
      <div>
        <AccountSelect
          selectedAccountAddress={selectedAccountAddress}
          setSelectedAccountAddress={setSelectedAccountAddress} />
        {selectedAccountAddress && (
          <BalanceAnnotation accountAddress={selectedAccountAddress} />
        )}
      </div>
    </div>
    {accountPair && (
      <ContributionStats accountPair={accountPair} />
    )}
    <div
      className={clsx(
        'md:flex',
        'md:items-center',
        'md:space-x-4',
        'space-y-8',
        'md:space-y-0'
      )}>
    </div>
  </section>
);

export default SupportTheKintsugiCrowdloanSection;
