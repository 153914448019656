
import ReactDOM from 'react-dom';
import {
  QueryClientProvider,
  QueryClient
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SubstrateProvider } from 'substrate-lib';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

const queryClient = new QueryClient();

ReactDOM.render(
  <SubstrateProvider>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </SubstrateProvider>,
  // TODO: should fix a bug with `SubstrateProvider` when `React.StrictMode` is used
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
