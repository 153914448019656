
import { KusamaAmount } from '@interlay/monetary-js';

import config from 'config';

// TODO: handle full crowdloan gracefully
// const CROWDLOAN_LIMIT = KusamaAmount.from.KSM(config.CROWDLOAN_LIMIT || '200000');
const PARA_ID = config.PARA_ID || '2092';
const FUND_ID = config.FUND_ID || '2092-34';
const MIN_KSM_CONTRIBUTION = config.MIN_KSM_CONTRIBUTION || '0.0999';
const BASE_REWARD = Number(config.BASE_REWARD || '3.75');
const EARLY_BIRD_REWARD = Number(config.EARLY_BIRD_REWARD || '0.05');
const REFERRAL_REWARD = Number(config.REFERRAL_REWARD || '0.05');
const SUCCESS_BONUS_1 = Number(config.SUCCESS_BONUS_1 || '0.025');
const SUCCESS_BONUS_2 = Number(config.SUCCESS_BONUS_2 || '0.05');
const SUCCESS_BONUS_3_GROSS = Number(config.SUCCESS_BONUS_3_GROSS || '0.5');
const SUCCESS_BONUS_1_THRESHOLD = KusamaAmount.from.KSM(config.SUCCESS_BONUS_1_THRESHOLD || '30000');
const SUCCESS_BONUS_2_THRESHOLD = KusamaAmount.from.KSM(config.SUCCESS_BONUS_2_THRESHOLD || '60000');
const SUCCESS_BONUS_3_THRESHOLD = KusamaAmount.from.KSM(config.SUCCESS_BONUS_3_THRESHOLD || '100000');
const STRONG_SUPPORTER_BONUS_PERCENTAGE_1 = Number(config.STRONG_SUPPORTER_BONUS_PERCENTAGE_1 || '0.5');
const STRONG_SUPPORTER_BONUS_PERCENTAGE_2 = Number(config.STRONG_SUPPORTER_BONUS_PERCENTAGE_2 || '5');
const STRONG_SUPPORTER_BONUS_PERCENTAGE_1_THRESHOLD =
  KusamaAmount.from.KSM(config.STRONG_SUPPORTER_BONUS_PERCENTAGE_1_THRESHOLD || '10');
const STRONG_SUPPORTER_BONUS_PERCENTAGE_2_THRESHOLD =
  KusamaAmount.from.KSM(config.STRONG_SUPPORTER_BONUS_PERCENTAGE_2_THRESHOLD || '1000');
const EARLY_BIRD_BONUS_END = Date.parse(config.EARLY_BIRD_BONUS_END || '04 Sep 2021 12:00:00 GMT');
const SKIP_REFERRAL_CONTRIBUTION_VALIDATION =
  config.SKIP_REFERRAL_CONTRIBUTION_VALIDATION === undefined ?
    true :
    !!config.SKIP_REFERRAL_CONTRIBUTION_VALIDATION;

export {
  PARA_ID,
  FUND_ID,
  MIN_KSM_CONTRIBUTION,
  BASE_REWARD,
  EARLY_BIRD_REWARD,
  REFERRAL_REWARD,
  SUCCESS_BONUS_1,
  SUCCESS_BONUS_2,
  SUCCESS_BONUS_3_GROSS,
  SUCCESS_BONUS_1_THRESHOLD,
  SUCCESS_BONUS_2_THRESHOLD,
  SUCCESS_BONUS_3_THRESHOLD,
  STRONG_SUPPORTER_BONUS_PERCENTAGE_1,
  STRONG_SUPPORTER_BONUS_PERCENTAGE_2,
  STRONG_SUPPORTER_BONUS_PERCENTAGE_1_THRESHOLD,
  STRONG_SUPPORTER_BONUS_PERCENTAGE_2_THRESHOLD,
  EARLY_BIRD_BONUS_END,
  SKIP_REFERRAL_CONTRIBUTION_VALIDATION
};
