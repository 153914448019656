
import clsx from 'clsx';

const HeroSection = (): JSX.Element => (
  <section>
    <div className='space-y-10'>
      <div className='space-y-10'>
        <h1
          className={clsx(
            'text-5xl',
            'text-center',
            'font-abcWhyte'
          )}>
          Kintsugi
        </h1>
        <p
          className={clsx(
            'bg-kintsugiMidnight',
            'shadow',
            'md:rounded-lg',
            'p-5',
            'text-lg',
            'text-justify'
          )}>
          {/* eslint-disable-next-line max-len */}
          Kintsugi&apos;s kBTC brings radically open Bitcoin to Kusama to kickstart liquidity for parachains like Karura, Shiden and Moonriver. Kintsugi is interBTC&apos;s canary network, an experiment with real economic value deployed on Kusama. Kintsugi and interBTC share the same code base - with the difference that Kintsugi focusses strictly on innovation and will always be ahead in terms of features.
        </p>
      </div>
      <div
        className={clsx(
          'shadow',
          'md:rounded-lg',
          'grid',
          'grid-cols-1',
          'md:grid-cols-3',
          'gap-10',
          'p-5',
          'bg-kintsugiMidnight',
          'text-lg',
          'text-justify'
        )}>
        <div>
          <h4
            className={clsx(
              'text-kintsugiOchre',
              'font-bold'
            )}>
            Community owned.
          </h4>
          <p>
            {/* eslint-disable-next-line max-len */}
            Any user or Vault providing liquidity to the bridge (BTC, KSM, ...), crowdloan contributors, early backers, and builders will steer the success of Kintsugi via their KINT tokens.
          </p>
        </div>
        <div>
          <h4
            className={clsx(
              'text-kintsugiOchre',
              'font-bold'
            )}>
            Radically open.
          </h4>
          <p>
            Anyone can become a Vault and help secure kBTC, anytime. Yes, you can run your own Vault!
          </p>
        </div>
        <div>
          <h4
            className={clsx(
              'text-kintsugiOchre',
              'font-bold'
            )}>
            Secured by Insurance.
          </h4>
          <p>
            {/* eslint-disable-next-line max-len */}
            Vaults lock collateral on the kBTC parachain in various digital assets. If Vaults misbehave, their collateral is slashed and users reimbursed. As a user, you only trust that Bitcoin and the DeFi platform you use are secure.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default HeroSection;
