import clsx from 'clsx';
import { INTERLAY_CROWDLOAN_LINK } from 'config/links';
import Panel from 'components/Panel';
import LinkButton from 'components/buttons/LinkButton';

const InterlayCrowdloanInformation = (): JSX.Element => (
  <Panel
    className={clsx(
      'space-y-4',
      'px-4',
      'py-5',
      'sm:p-6'
    )}>
    <h2
      className={clsx(
        'text-3xl',
        'text-center'
      )}>
        The Interlay crowdloan on Polkadot has started!
    </h2>
    <p
      className={clsx(
        'text-2xl',
        'text-center'
      )}>
      {/* eslint-disable-next-line max-len */}
      Vote for Interlay, bring Bitcoin DeFi onto Polkadot &#8212; and claim a 2.5% bonus if you contributed to the Kintsugi crowdloan!
    </p>
    <div
      className={clsx(
        'mt-4',
        'flex',
        'justify-center'
      )}>
      <LinkButton href={INTERLAY_CROWDLOAN_LINK}>
        Contribute now
      </LinkButton>
    </div>
  </Panel>
);

export default InterlayCrowdloanInformation;
