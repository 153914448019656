
import clsx from 'clsx';

import { ReactComponent as One } from 'assets/img/steps/one.svg';
import { ReactComponent as Two } from 'assets/img/steps/two.svg';
import { ReactComponent as Three } from 'assets/img/steps/three.svg';
import { ReactComponent as Four } from 'assets/img/steps/four.svg';
import { ReactComponent as LockStep } from 'assets/img/steps/1-lock.svg';
import { ReactComponent as MintStep } from 'assets/img/steps/2-mint.svg';
import { ReactComponent as DefiStep } from 'assets/img/steps/3-defi.svg';
import { ReactComponent as RedeemStep } from 'assets/img/steps/4-redeem.svg';

const STEP_TEXTS = {
  lockStep: {
    title: 'LOCK',
    // eslint-disable-next-line max-len
    text: 'Lock your BTC with a Vault. Pick one, or run your own. Your BTC is always safe and insured by Vault collateral.'
  },
  mintStep: {
    title: 'MINT',
    text: 'Get kBTC at a 1:1 ratio to your locked BTC.'
  },
  defiStep: {
    title: 'BTC DEFI',
    text: 'Earn on your Bitcoin. Use kBTC as collateral, for lending, yield farming and more - on any blockchain.'
  },
  redeemStep: {
    title: 'REDEEM',
    text: 'Redeem kBTC for actual BTC on Bitcoin - trustless and anytime you want.'
  }
};

interface StepInfoProps {
  title: string,
  text: string,
}

const StepInfo = ({
  title,
  text
}: React.ComponentPropsWithRef<'div'> & StepInfoProps): JSX.Element => (
  <div className='space-y-2'>
    <h1
      className={clsx(
        'text-3xl',
        'text-center'
      )}>
      {title}
    </h1>
    <p className='text-center'>
      {text}
    </p>
  </div>
);

const StepContainer = ({
  className,
  ...rest
}: React.ComponentPropsWithRef<'div'>) => (
  <div
    className={clsx(
      'space-y-6',
      className
    )}
    {...rest} />
);

const HowItWorksSection = (): JSX.Element => (
  <section
    className={clsx(
      'space-y-10'
    )}>
    <div className='space-y-2'>
      <h1
        className={clsx(
          'text-4xl',
          'text-center',
          'font-abcWhyte'
        )}>
        How it Works
      </h1>
      <p
        className={clsx(
          'text-center',
          'text-xl',
          'font-bold',
          'text-kintsugiOchre'
        )}>
        From Bitcoin to DeFi in just a few clicks.
      </p>
    </div>
    <div
      className={clsx(
        'bg-kintsugiMidnight',
        'shadow',
        'md:rounded-lg',
        'p-5',
        'text-lg',
        'text-justify',
        'grid',
        'grid-cols-1',
        'sm:grid-cols-2',
        'md:grid-cols-4',
        'gap-6'
      )}>
      <StepContainer>
        <One
          className={clsx(
            'h-24',
            'm-auto'
          )} />
        <LockStep
          className={clsx(
            'h-32',
            'm-auto'
          )} />
        <StepInfo
          title={STEP_TEXTS.lockStep.title}
          text={STEP_TEXTS.lockStep.text} />
      </StepContainer>
      <StepContainer>
        <Two
          className={clsx(
            'h-24',
            'm-auto'
          )} />
        <MintStep
          className={clsx(
            'h-32',
            'm-auto'
          )} />
        <StepInfo
          title={STEP_TEXTS.mintStep.title}
          text={STEP_TEXTS.mintStep.text} />
      </StepContainer>
      <StepContainer>
        <Three
          className={clsx(
            'h-24',
            'm-auto'
          )} />
        <DefiStep
          className={clsx(
            'h-32',
            'm-auto'
          )} />
        <StepInfo
          title={STEP_TEXTS.defiStep.title}
          text={STEP_TEXTS.defiStep.text} />
      </StepContainer>
      <StepContainer>
        <Four
          className={clsx(
            'h-24',
            'm-auto'
          )} />
        <RedeemStep
          className={clsx(
            'h-32',
            'm-auto'
          )} />
        <StepInfo
          title={STEP_TEXTS.redeemStep.title}
          text={STEP_TEXTS.redeemStep.text} />
      </StepContainer>
    </div>
  </section>
);

export default HowItWorksSection;
