
import { KusamaAmount } from '@interlay/monetary-js';

import {
  STRONG_SUPPORTER_BONUS_PERCENTAGE_1,
  STRONG_SUPPORTER_BONUS_PERCENTAGE_2,
  STRONG_SUPPORTER_BONUS_PERCENTAGE_1_THRESHOLD,
  STRONG_SUPPORTER_BONUS_PERCENTAGE_2_THRESHOLD
} from 'utils/helpers/constants-config';
import config from 'config';

const getStrongSupporterBonusPercentage = (
  ksmContributionAmount: KusamaAmount
): number => {
  let strongSupporterBonusPercentage;
  // MEMO: y (strong supporter bonus percentage) = k * x (base KINT reward) + b
  if (ksmContributionAmount.gte(STRONG_SUPPORTER_BONUS_PERCENTAGE_2_THRESHOLD)) {
    strongSupporterBonusPercentage = STRONG_SUPPORTER_BONUS_PERCENTAGE_2;
  } else if (ksmContributionAmount.gte(STRONG_SUPPORTER_BONUS_PERCENTAGE_1_THRESHOLD)) {
    const k =
      (STRONG_SUPPORTER_BONUS_PERCENTAGE_2 - STRONG_SUPPORTER_BONUS_PERCENTAGE_1) /
      // eslint-disable-next-line max-len
      (Number(config.STRONG_SUPPORTER_BONUS_PERCENTAGE_2_THRESHOLD) - Number(config.STRONG_SUPPORTER_BONUS_PERCENTAGE_1_THRESHOLD));
    // eslint-disable-next-line max-len
    const b = STRONG_SUPPORTER_BONUS_PERCENTAGE_1 - k * Number(config.STRONG_SUPPORTER_BONUS_PERCENTAGE_1_THRESHOLD);
    const floatAmount = Number(ksmContributionAmount.to.KSM().round().toNumber() || '0');
    strongSupporterBonusPercentage = k * floatAmount + b;
  } else {
    strongSupporterBonusPercentage = 0;
  }

  return strongSupporterBonusPercentage;
};

export default getStrongSupporterBonusPercentage;
